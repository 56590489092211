import React from 'react';
import EasyJobsSteps from 'assets/EasyStep3.jpeg';
import { Fade } from 'react-reveal';

const EasySteps = () => {
  return (
    <>
      {/* <div className="w-full">
        <div className="mx-auto pt-20">
          <div class="grid lg:grid-cols-2 grid-cols-1 justify-center items-center">
            <div className="h-[300px] w-[300px] border-2 ">
              <img
                className="object-cover w-full h-full rounded-2xl"
                width="100%"
                height="100%"
                src={EasyJobsSteps}
                alt=""
              />
            </div>
            <div class="flex ms-5 flex-col gap-5">
              <h1 className="text-3xl font-medium hover:underline transition ease-in-out duration-300 cursor-pointer hover:underline-offset-2">
                Comprehensive Web Security Analysis
              </h1>
              <p className="text-gray-400  font-normal">
                The number of UK businesses transacting online is at an all-time
                high.
              </p>
              <div className=" flex gap-3">
                <p className="text-gray-500">
                  As the world moves online, it is critical for organizations to
                  understand their risks in terms of security from both an
                  external and internal perspective. The ideal of a strong
                  perimeter protected by a firewall no longer stands. The move
                  to Cloud computing, Web Applications, and Remote Working means
                  there are multiple entry points into the network.
                </p>
              </div>
              <div className=" flex gap-3">
                <p className="text-gray-500">
                  Root Cyber can help to provide assurance that the relevant
                  controls in place are effective at preventing unauthorized
                  access by simulating an attack to gain access, exploit
                  security flaws, and attempt to escalate privileges.
                </p>
              </div>
              <div className=" flex gap-3">
                <p className="text-gray-500">
                  A comprehensive report will be provided at the end of testing
                  which not only gives technical details of the problem and
                  remedial recommendations, but also a summary highlighting the
                  business risk and non-conformance to security standards or
                  regulations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <article class="mx-2 my-10 max-w-screen-lg rounded-md border border-gray-100 text-gray-700 shadow-md md:mx-auto">
        <div class="flex flex-col md:flex-row">
          <div class="p-5 md:w-4/6 md:p-8">
            <Fade left>
              <div>
                <span class="rounded-md bg-sky-400 px-2 py-1 text-xs uppercase text-white">
                  Security
                </span>
                <p class="mt-2 text-xl font-black md:mt-6 md:text-4xl">
                  Comprehensive Web Security Analysis
                </p>
                <p class="mt-3 text-gray-600">
                  As the world moves online, it is critical for organizations to
                  understand their risks in terms of security from both an
                  external and internal perspective. The ideal of a strong
                  perimeter protected by a firewall no longer stands. The move
                  to Cloud computing, Web Applications, and Remote Working means
                  there are multiple entry points into the network.
                </p>
                <p class="mt-3 text-gray-600">
                  Root Cyber can help to provide assurance that the relevant
                  controls in place are effective at preventing unauthorized
                  access by simulating an attack to gain access, exploit
                  security flaws, and attempt to escalate privileges.
                </p>
              </div>
            </Fade>
          </div>
          <div class="mx-auto hidden items-center px-5 md:flex md:p-8">
            <Fade right>
              <img
                class="rounded-md shadow-lg"
                src={EasyJobsSteps}
                alt="Shop image"
                height="400px"
                width="400px"
              />
            </Fade>
          </div>
        </div>
      </article>
    </>
  );
};

export default EasySteps;
