import Hero from 'components/Hero/Hero';
import { FaRegBuilding } from 'react-icons/fa';
import { CiBank } from 'react-icons/ci';
import { MdOutlineHouse } from 'react-icons/md';
import AboutServices from 'components/AboutServices/AboutServices';
import AboutServicesCards from 'components/AboutServicesCards/AboutServicesCards';
import EasyStep3 from 'assets/EasySteps2.jpg';
import Faq from 'components/FAQ/Faq';
import { Fade } from 'react-reveal';
import { useEffect } from 'react';

const About = () => {
  const cardsData = [
    {
      icon: FaRegBuilding,
      title: 'Security Consultancy',
      description:
        'Root Cyber is an information security consultancy working with business, helping clients to identify and mitigate security risks in business environments. We help clients across all industry sectors to protect the Confidentiality, Availability and Integrity of business critical systems, websites, applications and data through a comprehensive risk based approached to testing.',
    },
    {
      icon: CiBank,
      title: 'Security Testing',
      description:
        'Root Cyber works with our clients by conducting security testing and "Ethical Hacking" to provide assurance against technical IT security threats and vulnerabilities that may otherwise be used by malicious 3rd parties or Hackers.',
    },
    {
      icon: MdOutlineHouse,
      title: 'Major Financial Loss',
      description:
        'These can often cause major financial loss in monetary terms and also significant damage to reputations, customer confidence and legal compliance. Penetration Testers assists clients with IT security and provides vendor neutral advise to minimise such risk.',
    },
  ];

  const aboutServiceData = [
    {
      image: EasyStep3,
      title: 'About Our Service',
      heading: 'Experienced and Certified Security Consultants',
      content: `Root Cyber is an information security consultancy working with businesses, helping clients to identify and mitigate security risks in business environments. We help clients across all industry sectors to protect the Confidentiality, Availability, and Integrity of business-critical systems, websites, applications, and data through a comprehensive risk-based approach to testing. Root Cyber works with our clients by conducting security testing and "Ethical Hacking" to provide assurance against technical IT security threats and vulnerabilities that may otherwise be used by malicious third parties or hackers. Our penetration testers assist clients with IT security and provide vendor-neutral advice to minimize such risks. Our consultants are all established security testers with many years of experience in the IT security and penetration testing industry, having undertaken CREST, Tiger, and CHECK Team Leader certifications.`,
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Hero
        welcomeText="About Root Cyber"
        para="Root Cyber is an information security consultancy working with business, helping clients to identify and mitigate security risks in business environments. "
        isbutton={false}
      />
      <AboutServices aboutServiceData={aboutServiceData} />
      <Fade bottom>
        <AboutServicesCards cardsData={cardsData} />
      </Fade>
      <Fade bottom>
        <Faq />
      </Fade>
    </>
  );
};

export default About;
