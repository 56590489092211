import React from 'react';

const AboutServicesCards = (cardsData) => {
  return (
    <>
      <div className="flex items-center  flex-row justify-center flex-wrap gap-x-5 gap-y-12 xs:px-0 px-4 py-20 lg:px-13 ">
        <div className="m-auto lg:w-10/12  sm:w-full  gap-4 flex items-center justify-center flex-wrap lg:flex-nowrap px-4">
          {cardsData.cardsData.map((card, index) => (
            <div key={index} className="flex  mt-10  sm:w-10/12 md:w-10/12">
              <div className="flex w-full max-w-full flex-col break-words rounded-lg border border-gray-100 bg-white text-gray-600 shadow-lg">
                <div className="p-3">
                  <div className="absolute flex justify-center items-center -mt-10 h-16 w-16 rounded-xl bg-gradient-to-tr  to-gray-400 text-center text-white shadow-lg bg-sky-600">
                    <card.icon style={{ fontSize: '35px' }} />
                  </div>
                  <div className="pt-1 text-right">
                    <p className="font-bold  capitalize ml-16 text-base">
                      {card.title}
                    </p>
                  </div>
                </div>
                <hr className="opacity-50" />
                <div className="p-4">
                  <p className="text-base">
                    {card?.description?.slice(0, 210) + '...'}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AboutServicesCards;
