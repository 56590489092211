import React from 'react';
// import styles from "./EasySteps.module.css"
import EasyJobsSteps from 'assets/EasySteps2.jpg';
import { Fade } from 'react-reveal';

const EasySteps3 = () => {
  return (
    <>
      <section class="mx-auto flex max-w-lg flex-col px-4 py-10 lg:max-w-screen-xl lg:flex-row">
        <div class="max-w-md pr-20 lg:pt-28">
          <img
            src="https://assets-global.website-files.com/6009ec8cda7f305645c9d91b/601074ed0f05cd25097215a4_6002086f72b7277e1f01d682_ryan-morrison-illustration-1.png"
            alt=""
          />
        </div>
        <div class="">
          <div class="mb-5 flex h-16 w-16 items-center justify-center rounded-full bg-sky-600 text-white shadow-lg shadow-sky-600/20">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-6 w-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"
              />
            </svg>
          </div>
          <h2 class="mb-10  text-4xl font-bold leading-snug lg:text-5xl lg:leading-snug">
            Your
            <span class="text-sky-600"> Partner</span> in Advanced Cybersecurity
            Solutions
          </h2>
          <div class="grid gap-y-12 gap-x-8 lg:grid-cols-2">
            <Fade left>
              <div>
                <p class="mb-6 border-l-4 border-sky-600 pl-4 text-2xl leading-10">
                  Security Insights
                </p>
                <p class="text-lg text-gray-800">
                  We provide detailed reports and actionable recommendations to
                  help you maintain robust security and stay ahead of potential
                  threats.
                </p>
              </div>
            </Fade>
            <Fade right>
              <div>
                <p class="mb-6 border-l-4 border-sky-600 pl-4 text-2xl leading-10">
                  Professional Services
                </p>
                <p class="text-lg text-gray-800">
                  Root Cyber provides world-class professional services with
                  extensive expertise in training, deployment, and security
                  assessments.
                </p>
              </div>
            </Fade>
            <Fade left>
              <div>
                <p class="mb-6 border-l-4 border-sky-600 pl-4 text-2xl leading-10">
                  Best Practices
                </p>
                <p class="text-lg text-gray-800">
                  We help organizations define security best practices to ensure
                  that their environments are protected from malicious threats.
                </p>
              </div>
            </Fade>
            <Fade right>
              <div>
                <p class="mb-6 border-l-4 border-sky-600 pl-4 text-2xl leading-10">
                  Partnering
                </p>
                <p class="text-lg text-gray-800">
                  By partnering with Root Cyber, your organization can effectively
                  achieve its vulnerability management and risk assessment.
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </>
  );
};

export default EasySteps3;
