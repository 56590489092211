import EasySteps from 'components/EasySteps/EasySteps';
import EasySteps2 from 'components/EasySteps2/EasySteps2';
import EasySteps3 from 'components/EasySteps3/EasySteps3';
import Hero from 'components/Hero/Hero';
import React, { useEffect } from 'react';
import { Fade } from 'react-reveal';
import Contactus from '../Contactus/Contactus';
import MyInfiniteScroll from 'components/OurPartner/OurPartner';
const Home = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero
        welcomeText="WELCOME TO Root Cyber"
        para="  As the world move online it is critical for organisations
                understand what their risks are in terms of security from both
                an external and internal perspective."
        isbutton={true}
      />
      <EasySteps3 />
      <MyInfiniteScroll/>
      <EasySteps />
      {/* <Fade bottom>
        <Contactus />
      </Fade> */}
    </div>
  );
};

export default Home;
