import React, { useState } from "react";

const Faq = () => {
  const questionsAndAnswer = [
    {
      question: "What is Root Cyber?",
      answer:
        "Root Cyber is an information security consultancy that helps businesses identify and mitigate security risks in their environments. We protect the Confidentiality, Availability, and Integrity of business-critical systems, websites, applications, and data through a comprehensive risk-based approach to testing.",
    },
    {
      question: "What services does Root Cyber offer?",
      answer:
        "We offer security testing and Ethical Hacking services to provide assurance against technical IT security threats and vulnerabilities. Our services help protect against financial loss, damage to reputation, customer confidence, and legal compliance issues.",
    },
    {
      question: "How does Root Cyber help protect businesses?",
      answer:
        "We conduct penetration testing to identify vulnerabilities that malicious third parties or hackers could exploit. Our experts provide vendor-neutral advice to minimize risks and enhance IT security.",
    },
    {
      question: "What is penetration testing?",
      answer:
        "Penetration testing, also known as ethical hacking, is a method of evaluating the security of a system or network by simulating an attack from malicious outsiders. This helps identify and fix security weaknesses before they can be exploited.",
    },
    {
      question: "Who conducts the security testing at Root Cyber?",
      answer:
        "Our security testers are highly experienced professionals with many years in the IT security and penetration testing industry. They hold certifications such as CREST, Tiger, and CHECK Team Leader.",
    },
    {
      question: "Which industries does Root Cyber serve?",
      answer:
        "We help clients across all industry sectors, providing tailored security solutions to meet the unique needs of each business environment.",
    },
    {
      question:
        " Why is it important to conduct security testing?",
      answer:
        "Security testing helps prevent significant financial losses, damage to reputation, loss of customer confidence, and legal compliance issues that can arise from security breaches.",
    },
    {
      question: "What makes Root Cyber different from other security consultancies?",
      answer:
        "Root Cyber provides comprehensive, risk-based security testing and vendor-neutral advice. Our consultants' extensive experience and industry-recognized certifications ensure high-quality service and reliable protection against IT security threats.",
    },
    {
      question:
        " What are the benefits of choosing Root Cyber?",
      answer:
        "By choosing Root Cyber, you benefit from our extensive experience, certified professionals, and a thorough approach to identifying and mitigating security risks. We help ensure the safety and integrity of your business-critical systems and data.",
    },
  ];
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <>
      <div class="relative mx-auto w-full py-16 px-5 font-sans text-gray-800 sm:px-20 md:max-w-screen-lg lg:py-24">
        <h2 class="mb-5 text-center font-sans text-4xl sm:text-5xl font-bold underline underline-offset-4 ">
          Frequently asked Questions
        </h2>
        <p class="mb-12 text-center text-lg text-gray-600">
          We have written down answers to some of the frequently asked
          questions. But, if you still have any queries, feel free to ping us on
          chat.
        </p>
        <ul class="space-y-4">
          {questionsAndAnswer?.map((item,index) => (
            <li key={index} class="text-left">
              <label
                for={`accordion-${index}`}
                class="relative flex flex-col rounded-md border border-gray-100 shadow-md"
              >
                <input
                  class="peer hidden"
                  type="checkbox"
                  id={`accordion-${index}`}
                  checked={openIndex === index}
                  onChange={() => handleToggle(index)}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="absolute right-0 top-4 ml-auto mr-5 h-4 text-sky-500 transition peer-checked:rotate-180"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
                <div class="relative ml-4 cursor-pointer select-none items-center py-4 pr-12">
                  <h3 class="text-sm text-gray-600 lg:text-base">
                    {item?.question}
                  </h3>
                </div>
                <div class="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96">
                  <div class="p-5">
                    <p class="text-sm text-gary-600">{item?.answer}</p>
                  </div>
                </div>
              </label>
            </li>
          ))}
        </ul>
    
      </div>
    </>
  );
};

export default Faq;
