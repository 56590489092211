import Marquee from 'react-fast-marquee';
import parterner1 from '../../assets/brands/brands (1).jpeg';
import parterner2 from '../../assets/brands/brands (1).jpg';
import parterner3 from '../../assets/brands/brands (1).png';
import parterner6 from '../../assets/brands/brands (2).jpg';
import parterner7 from '../../assets/brands/brands (2).png';
import parterner10 from '../../assets/brands/brands (3).jpg';
import parterner11 from '../../assets/brands/brands (3).png';
import parterner14 from '../../assets/brands/brands (4).webp';
import parterner15 from '../../assets/brands/brands (5).png';
import parterner16 from '../../assets/brands/brands (6).png';
import parterner17 from '../../assets/brands/brands (7).png';
import parterner18 from '../../assets/brands/brands (8).png';

const MyInfiniteScroll = () => {
  const ourParterners = [
    parterner1,
    parterner2,
    parterner3,
    parterner6,
    parterner7,
    parterner10,
    parterner11,
    parterner14,
    parterner15,
    parterner16,
    parterner17,
    parterner18,
  ];
  return (
    <>
      <div>
        <h1 className="flex items-center justify-center mx-auto text-5xl ">
          Team Member Certifications
        </h1>
        <Marquee speed={80}>
          {ourParterners.map((item, index) => (
            <div className="h-[200px] w-[200px] flex items-center my-2 px-2">
              <img src={item} alt={item} height="100%" width="100%" />
            </div>
          ))}
        </Marquee>
      </div>
    </>
  );
};

export default MyInfiniteScroll;
