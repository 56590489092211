import React from 'react';
import { FaFacebook } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { FaLinkedin } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import Logo from 'assets/logo.png';
function Footer() {
  const navigate = useNavigate();
  const INFORMATION = [
    {
      title: 'Home',
      link: '/',
    },
    {
      title: 'Training',
      link: '/',
    },
    {
      title: 'Testing',
      link: '/',
    },
    {
      title: 'About Us',
      link: '/about',
    },
    // {
    //   title: 'Contact Us',
    //   link: '/contact-us',
    // },
  ];
  const ACCOUNTS = [
    {
      title: 'WebApplication Security Testing',
      link: '/service',
    },
    {
      title: 'Internal Penetration Tests',
      link: '/service',
    },
    {
      title: 'StolenLaptop And HostBuild Review Tests',
      link: '/service',
    },
    {
      title: 'Remote Worker Assessment',
      link: '/service',
    },
  ];

  const handleNavigate = () => {
    navigate('/');
  };

  return (
    <footer class="bg-sky-950 text-white">
      <div class=" w-11/12 mx-auto grid max-w-full gap-y-8 gap-x-10  py-10 md:grid-cols-4 xl:grid-cols-4">
        <div class="max-w-sm">
          <div
            onClick={handleNavigate}
            class="mb-9 flex h-10 w-[200px] items-center cursor-pointer"
          >
            <img src={Logo} height="100%" width="100%" />
          </div>
          <div class="text-[14px]">
            As the world move online it is critical for organisations understand
            what their risks are in terms of security from both an external and
            internal perspective. The ideal of a strong perimeter protected by a
            firewall no longer stands. The move to Cloud computing, Web
            Applications and Remote Working means there are multiple entry
            points into the network.
          </div>
          {/* <div
            className="flex gap-3 mt-5"
            style={{ fontSize: 'clamp(18px,4vw,30px)' }}
          >
            <FaFacebook />

            <FaXTwitter />

            <FaLinkedin />

            <FaYoutube />
          </div> */}
        </div>

        <div class="">
          <div class="mt-4 mb-2 font-medium xl:mb-4 text-[16px]">
            INFORMATION
          </div>
          <nav aria-label="Footer Navigation">
            <ul class="space-y-3">
              {INFORMATION?.map((item) => (
                <li>
                  <Link
                    class="hover:text-sky-500 hover:underline underline-offset-4"
                    to={item?.link}
                  >
                    {item?.title}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div class="">
          <div class="mt-4 mb-2 font-medium xl:mb-4 text-[16px]">
            OTHER SERVICES
          </div>
          <nav aria-label="Footer Navigation ">
            <ul class="space-y-3">
              {ACCOUNTS?.map((item) => (
                <li>
                  <Link
                    class="hover:text-sky-500 hover:underline underline-offset-4"
                    to={item?.link}
                  >
                    {item?.title}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div class="">
          <div class="mt-4 mb-2 font-medium xl:mb-4 text-[16px] font-Raleway">
            CONTACT US
          </div>
          <div class="flex flex-col">
            <div class="mb-4">
              <p>Address :</p>
              <p>
                Office 6520 321-323 High Road Chadwell Heath Essex RM6 6AX UK
              </p>

              <p>Phone :</p>
              <p>01869222165</p>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class=" w-11/12 mx-auto flex max-w-full flex-col gap-y-4 py-3 text-center  sm:flex-row sm:justify-between sm:text-left">
          <div class="">
            © 2024 Copy Rights | All Rights Reserved | By Root Cyber
          </div>
          <div class="flex text-center items-center justify-center gap-3">
            <a class="" href="#">
              Develope By
            </a>
            <span>|</span>
            <a class="" href="#">
              Muhammad Ali
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
