import React from 'react';
import { Fade } from 'react-reveal';

const AboutServices = (aboutServiceData) => {
  return (
    <>
      <div className="m-auto ">
        {aboutServiceData.aboutServiceData.map((data, index) => (
          <div
            key={index}
            className="flex w-10/12 flex-row-reverse md:px-8 flex-wrap lg:flex-nowrap mx-auto items-center min-h-80 gap-10 mt-20 mb-10 "
          >
            <Fade right>
              <div className="w-full lg:w-6/12  ">
                <img className="w-full" src={data.image} alt="" />
              </div>
            </Fade>
            <Fade left>
              <div className="w-full lg:w-6/12  ">
                <div className="flex flex-col ">
                  <span className="text-sky-600 text-4xl  2xl:text-6xl	">
                    --
                  </span>
                  <p className="pt-1 text-xl 2xl:text-3xl font-bold">
                    {data.title}
                  </p>
                  <h1 className="text-4xl text-sky-600  2xl:text-6xl xs:text-xl pt-2 pb-5 font-semibold ">
                    {data.heading}
                  </h1>
                  <p className="font-normal text-gray-500 text-md 2xl:text-2xl xs:text-sm pb-5">
                    {data.content}
                  </p>
                </div>
                <hr />
              </div>
            </Fade>
          </div>
        ))}
      </div>
    </>
  );
};

export default AboutServices;
